(function ($) {
    /**
     * powiązane ze scrollem
     */

    var slider = $('#js-home-products-slider');

    slider.slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        appendDots: $('#js-home-reviews-dots'),
        prevArrow:
            '<button type="button" class="home-products-slider__arrow home-products-slider__arrow--prev slick-prev"><i class="icon icon-arrow-left home-products-slider__arrow-icon"></i></button>',
        nextArrow:
            '<button type="button" class="home-products-slider__arrow home-products-slider__arrow--next slick-next"><i class="icon icon-arrow-right home-products-slider__arrow-icon"></i></button>',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            }
          }
        ]
    });

})(jQuery);