
var documentReady = function () {
    require('./parallax/jquery.event.frame');
    require('./parallax/jquery.parallax');
    require('./scripts/about-slick');
    require('./scripts/ajax-products-filters');
    require('./scripts/back-button');
    require('./scripts/cart');
    require('./scripts/checkout');
    require('./scripts/filter-menu');
    require('./scripts/gastroJS');
    require('./scripts/home-popover');
    require('./scripts/home-slick');
    require('./scripts/jparallax');
    require('./scripts/knowledge-filter');
    require('./scripts/leasing-calculator');
    require('./scripts/menu-scroll');
    require('./scripts/price-variations');
    require('./scripts/print-button');
    require('./scripts/product-colors');
    require('./scripts/product-reviews');
    require('./scripts/product-slick');
    require('./scripts/quantity-input');
    require('./scripts/reviews-slick');
    require('./scripts/scroll-to-fixed-init');
    require('./scripts/select-check');
    require('./scripts/select2');
    require('./scripts/smooth-scroll');
    require('./scripts/terminalJS');
    require('./scripts/tooltips');
};

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
    documentReady();

} else {
    document.addEventListener("DOMContentLoaded", documentReady);
}
