(function ($) {
    /**
     * wyświetlanie tooltipów do pinów na stronie głównej
     */

    var popoverTrigger = $('.js-slide-pin')
    var popover = $('.js-slide-popover')

    $(window).resize(function() {
        popover.each(function() {
            $(this).removeClass('active')
        })
    });
    
    popoverTrigger.click(function() {
        var target = $(this).attr('data-target'),
            position = $(this).offset()
        
        if ($(target).hasClass('active')) {
            $(target).removeClass('active')

            popover.each(function() {
                $(this).removeClass('active')
            })
        }
        else {
            popover.each(function() {
                $(this).removeClass('active')
            })
            
            var direction = $(target).attr('data-direction'),
                width = $(target).width(),
                height = $(target).height(),
                left = 20,
                top = 20

            switch (direction) {
                case 'top-right':
                    left = -20 - parseInt(width) 
                    break;
                case 'bottom-right':
                    top = -20 - parseInt(height)
                    left = -20 - parseInt(width) 
                    break;
                case 'bottom-left':
                    top = -20 - parseInt(height)
                    break;
            }

            $(target).css({
                'left': parseInt(position.left) + left,
                'top': parseInt(position.top) + top,
            }).addClass('active')
        }
    })

    var slider = $('#js-home-clients-slider')

    slider.slick({
        dots: true,
        arrows: false, 
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: $('#js-home-clients-dots'),
    })

    slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        popover.each(function() {
            $(this).removeClass('active')
        })
    });

    var closePopover = '.js-close-popover'
    
    $('body').on('click', closePopover, function() {
        popover.each(function() {
            $(this).removeClass('active')
        })
    })

})(jQuery);