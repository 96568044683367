(function ($) {


    /**
     * Przycisk powrotu do poprzedniej strony
     */
    window.onload = function () {
        var back = document.getElementById('backButton');
        if (back) {
            back.onclick = function () {
                window.history.back();
            };
        }
    }

    var slider = $('#js-reviews-section-slider');

    slider.slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        fade: true,
        cssEase: 'linear',
        slidesToScroll: 1,
        speed: 500,
        appendDots: $('#js-reviews-section-dots'),
        prevArrow:
            '<button type="button" class="home-products-slider__arrow home-products-slider__arrow--prev slick-prev"><i class="icon icon-arrow-left home-products-slider__arrow-icon"></i></button>',
        nextArrow:
            '<button type="button" class="home-products-slider__arrow home-products-slider__arrow--next slick-next"><i class="icon icon-arrow-right home-products-slider__arrow-icon"></i></button>',
    });

    var $back = $('#js-floating-back'),
        $backLimit = $('#js-floating-back-end')


    $(document).on("show_variation", function(){

        //pvc
        let picked_variant = $('#pa_wymiary-szalki').val();
        let picked_variant_ergo = $('#product-3910 #pa_sposob-polaczenia-z-crk').val();
        let picked_variant_ergo_usb = $('#product-3910 #pa_kabel-usb-a-a-do-netshare').val();
        let picked_variant_ergo_charger = $('#product-3910 #pa_ladowarka-do-kasy').val();

        if (picked_variant == '420w-x-520d-mm') {
            if ($("#pa_wymiary-szalki option[value='420w-x-520d-mm']").is(':selected') && $("#pa_zakres-wazenia-dokladnosc option[value='30kg-10g']").is(':selected')){
                $("#pa_zakres-wazenia-dokladnosc option").val("150-kg-50-g").trigger('change');
            }
            $("#pa_zakres-wazenia-dokladnosc option[value='30kg-10g']").attr('disabled', true);

        } else if (picked_variant == '350w-x-450d-mm'){
            if ($("#pa_wymiary-szalki option[value='350w-x-450d-mm']").is(':selected') && $("#pa_zakres-wazenia-dokladnosc option[value='300-kg-100-g']").is(':selected')) {
                $("#pa_zakres-wazenia-dokladnosc option").val("150-kg-50-g").trigger('change');
            }
            $("#pa_zakres-wazenia-dokladnosc option[value='300-kg-100-g']").attr('disabled', true);
        } else if (picked_variant_ergo != 'netshare') {
            $("#product-3910 #pa_ladowarka-do-kasy option[value='nie']").attr('disabled', true);
            $("#pa_kabel-usb-a-a-do-netshare option[value='tak']").attr('disabled', true);
            $("#pa_kabel-usb-a-a-do-netshare option[value=nie]").attr('selected', 'selected');
        }else{
            $("#product-3910 #pa_ladowarka-do-kasy option[value='nie']").attr('disabled', false);
            $("#pa_kabel-usb-a-a-do-netshare option[value='tak']").attr('disabled', false);
        }
        // console.log(picked_variant_ergo_usb);

        // console.log(picked_variant_ergo);
        
        if (picked_variant_ergo == "netshare" && picked_variant_ergo_charger == "nie") {
            $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='bt-wifi']").attr('disabled', true);
            $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='bt-gprs-bez-karty-sim']").attr('disabled', true);
            $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='gprs-karta-sim-max-3-lata']").attr('disabled', true);
        } else if (picked_variant_ergo == "netshare" && picked_variant_ergo_usb == "tak"){
            // $("#product-3910 #pa_ladowarka-do-kasy option[value=tak]").attr('selected', 'selected');
            $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='bt-wifi']").attr('disabled', true);
            $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='bt-gprs-bez-karty-sim']").attr('disabled', true);
            $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='gprs-karta-sim-max-3-lata']").attr('disabled', true);
        }else{
            $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='bt-wifi']").attr('disabled', false);
            $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='bt-gprs-bez-karty-sim']").attr('disabled', false);
            $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='gprs-karta-sim-max-3-lata']").attr('disabled', false);
        }

        // console.log(picked_variant_ergo == "netshare" && picked_variant_ergo_charger == "nie");


        // if (picked_variant_ergo_charger == null) {
        //     $("#pa_ladowarka-do-kasy option[value=nie]").attr('selected', 'selected');
        //     $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='bt-wifi']").attr('disabled', true);
        //     $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='bt-gprs-bez-karty-sim']").attr('disabled', true);
        //     $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='gprs-karta-sim-max-3-lata']").attr('disabled', true);
        // } 


        // if (picked_variant_ergo_usb == null) {
        //     console.log('wybrano usb wariant: nie');
        //     $("#pa_kabel-usb-a-a-do-netshare option[value=nie]").attr('selected', 'selected');
        // } else if (picked_variant_ergo_usb == 'tak') {
        //     console.log('wybrano usb wariant: tak');
        //     $("#pa_kabel-usb-a-a-do-netshare option[value=tak]").attr('selected', 'selected');
        //     $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='bt-wifi']").attr('disabled', true);
        //     $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='bt-gprs-bez-karty-sim']").attr('disabled', true);
        //     $("#product-3910 #pa_sposob-polaczenia-z-crk  option[value='gprs-karta-sim-max-3-lata']").attr('disabled', true);
        // }

        // console.log(picked_variant_ergo_usb);
    });

    $(document).ready(function() {

        /**
         * scroll to fix - inicjacja wtyczki
         */
        $back.scrollToFixed({
            removeOffsets: true,
            marginTop: 270,
            limit: function(){
                console.log($backLimit.offset().top)
                return $backLimit.offset().top - window.innerHeight - 100;
            }
        });

        $('.js-smooth-scroll').on('click', function (e) {
            e.preventDefault();
            $(document).off('scroll');

            var target = this.hash,
                menu = target;
            $target = $(target);
            $('html, body')
                .stop()
                .animate(
                    {
                        scrollTop: $target.offset().top - 120,
                    },
                    500,
                    'swing',
                    function () {
                        window.location.hash = target;
                        $(document).on('scroll', onScrollOne);
                    }
                );
        });
        
    });
})(jQuery);



/**
 * Required event: Show banner
 */
 function showCookieBanner() {

  var cookieBanner = document.getElementById('CookieBanner');
  if (cookieBanner) {
    cookieBanner.classList.add('is-visible-cookie-banner');
    cookieBanner.classList.remove('is-closing-cookie-banner');
    cookieBanner.style.display = 'flex';
  }
  document.body.classList.add('is-cookie-banner');

}

/**
 * Required event: Hide banner
 */
function hideCookieBanner() {

  var cookieBanner = document.getElementById('CookieBanner');
  if (cookieBanner) {
    cookieBanner.classList.add('is-closing-cookie-banner');
    // hide element after CSS animation (250ms)
    setTimeout(function(cookieBanner) {
      cookieBanner.classList.remove('is-visible-cookie-banner');
      cookieBanner.style.display = 'none';
      document.body.classList.remove('is-cookie-banner');
    }, 250, cookieBanner);
  } else {
    document.body.classList.remove('is-cookie-banner');
  }

}

/**
 * Event: Show cookie categories
 */
function eventCookieBannerDetailsClicked(e) {
  var cookieBannerWrapper = document.getElementById('CookieBanner');
  if (cookieBannerWrapper) {
     cookieBannerWrapper.classList.toggle('is-details-open');
  }
  if (e.currentTarget) {
     if (e.currentTarget.getAttribute('aria-expanded') == 'false') {
      e.currentTarget.setAttribute('aria-expanded', 'true');
     } else {
      e.currentTarget.setAttribute('aria-expanded', 'false');
     }
  }
}

/**
 * Event: Show details of cookie category
 */
function eventCookieBannerToggleTable(e) {

  e.preventDefault();
  e.stopPropagation();

  if (e.currentTarget) {
    var targetId = e.currentTarget.getAttribute('aria-controls');
    if (targetId) {
      var targetEl = document.getElementById(targetId);
      if (targetEl) {
        if (e.currentTarget.getAttribute('aria-expanded') == 'false') {
          e.currentTarget.setAttribute('aria-expanded', 'true');
          targetEl.classList.add('is-open');
         } else {
          e.currentTarget.setAttribute('aria-expanded', 'false');
          targetEl.classList.remove('is-open');
         }
      }
    }
  }

}

function cbMainHide() {
  document.getElementById("cb_main").style.display = "none";
}