(function ($) {
    
    //Wyłączenie rozowego koloru z wyboru
    $(document).on("show_variation", function () {

        $('#product-6924 #pa_sposob-polaczenia-z-crk').change(function () {
            let picked_color = $('#product-6924 #pa_sposob-polaczenia-z-crk').val();

            if (picked_color == 'bt-gprs-bez-karty-sim' || picked_color == 'bt-gprs-z-karta-sim-oplacona-na-2-lata') {
                $(".js-item-pink-color").addClass('product-colors__label--disabled');
                $(".js-pink-element").addClass('product-colors__item--white');
                $("#attribute_pa_kolor-rozowy").attr("disabled", true);
            } else {
                $(".js-item-pink-color").removeClass('product-colors__label--disabled');
                $(".js-pink-element").removeClass('product-colors__item--white');
                $("#attribute_pa_kolor-rozowy").attr("disabled", false);
            }
        });


    });


    var radio = '.js-wc-product-colors input[name="attribute_pa_kolor"]';

    $(document).on('change', radio, function () {

        $(radio + ':checked').each(function(index, element) {
            var $el = $(element),
                thisName = $el.attr('name'),
                thisVal = $el.attr('value');
            
            $('select[name="'+thisName+'"]').val(thisVal).trigger('change');
        });
        
        $(radio).parent().removeClass('product-colors__item--current'); // Remove class from label
        $(radio + ':checked').parent().addClass('product-colors__item--current'); 
        


    });

    $(document).on('woocommerce_update_variation_values', function () {
        $(radio).each(function(index, element) {
            var $el = $(element),
                thisName = $el.attr('name'),
                thisVal = $el.attr('value');
            
            $el.removeAttr('disabled');

            if( $('select[name="'+thisName+'"] option[value="'+thisVal+'"]').is(':disabled') ) {
                $el.prop('disabled', true);
            }
        });
    });

    // Click reset variations
    $('.js-wc-reset-variations').on('click', function (e) { 
        $(radio).parent().removeClass('product-colors__item--current'); // Remove class from label
    })

    // var variation_id = 0;
    // $('form.variations_form .variation_id').on('change', function (event, data) {
    //     variation_id = $(this).val();
    // });
    
    // $('#crossSellingModal').on('show.bs.modal', function (e) {
    //     setTimeout(() => {
    //         $('.js-add-to-cart-form-modal').find('.variation_id').val(variation_id);
    //     }, 500);
    // })

})(jQuery);