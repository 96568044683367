
if (document.body.classList.contains('page-landing-template-gastro')) {
    // VIDEO MODAL

    const videoButtonNodes = document.querySelectorAll(".gastro-slider__video")

    const videoModal = document.querySelector(".videoModal")
    const videoModalVideo = document.querySelector(".videoModal__video")

    videoButtons = Array.from(videoButtonNodes)
   
    videoButtons.forEach(function(element) {
        element.addEventListener('click', function(e) {
         
            const videoURL = e.target.getAttribute('href')

            videoModalVideo.setAttribute('src', videoURL + '?autoplay=1')

            videoModal.classList.add('videoModal--active')
        })
    })
    videoModal.addEventListener('click', function(e) {
        videoModal.classList.remove('videoModal--active');
        videoModalVideo.setAttribute('src', '')
    })


    // UPDATE VIDEO WRAPPER

    if (window.innerWidth >= 1366) {
        const videoWrapper = document.querySelector(".gastro-slider__videoWrapper")
        const videoItemsNode = document.querySelectorAll(".gastro-slider__video")

        const videoItems = Array.from(videoItemsNode)
        
        videoItems.forEach(function(item) {
            item.innerHTML = '<svg width="60" height="60" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40ZM48.9869 39.2446L36.3867 31.1444C35.7877 30.7594 35 31.1894 35 31.9015V48.1018C35 48.8138 35.7877 49.2439 36.3867 48.8589L48.9869 40.7587C49.538 40.4044 49.538 39.5988 48.9869 39.2446Z" fill="white"/></svg>'
        })
    }

    // SCOLL

    const productSection = document.querySelector('#productList');
    const formSection = document.querySelector('#formSection');
    const benefitSection = document.querySelector('#benefitSection');
    // const heroButton = document.querySelector('.gastro-hero__button');
    const formButtonNode = document.querySelectorAll('.js-gastro-form__button');
    // const heroBenefitButton = document.querySelector('.js-gastro-benefit__button');

    const formButtonList = Array.from(formButtonNode);

    // Works on Safari
    function SmoothVerticalScrolling(e, time, where) {
        var eTop = e.getBoundingClientRect().top;
        var eAmt = eTop / 100;
        var curTime = 0;
        while (curTime <= time) {
            window.setTimeout(SVS_B, curTime, eAmt, where);
            curTime += time / 100;
        }
    }

    function SVS_B(eAmt, where) {
        if (where == 'center' || where == '') window.scrollBy(0, eAmt / 2);
        if (where == 'top') window.scrollBy(0, eAmt);
    }

    // heroBenefitButton.addEventListener('click', function(e) {
    //     SmoothVerticalScrolling(benefitSection, 500, 'top');
    // });

    // heroButton.addEventListener('click', function(e) {
    //     SmoothVerticalScrolling(productSection, 500, 'top');
    // });

    // formButtonList.forEach(function(item) {
    //     item.addEventListener('click', function(e) {
    //         SmoothVerticalScrolling(formSection, 500, 'top');
    //     });
    // });

    // UPDATE FORM

    const product1 = document.querySelector('#product1');
    const product2 = document.querySelector('#product2');
    const product3 = document.querySelector('#product3');
    const product4 = document.querySelector('#product4');
    const product5 = document.querySelector('#product5');

    const gastroFormProduct = document.querySelector('.gastro-form-contact__input[name="product"]');

    const productButtonNode = document.querySelectorAll('.js-gastro-form__button');
    const productButton = Array.from(productButtonNode);

    productButton.forEach(function(elem) {
        elem.addEventListener('click', function(e) {
            
            switch (e.target.id) {
                case 'product1':
                    gastroFormProduct.value = 'Novitus Next Online z modułem gastronomicznym';
                  
                    break;
                case 'product2':
                    gastroFormProduct.value = 'Novitus Next Onlie ze Storyous';
                   
                    break;
                case 'product3':
                    gastroFormProduct.value = 'Drukarka Novitus HD Online i tablet ze Storyous';
                   
                    break;
                case 'product4':
                    gastroFormProduct.value = 'One Menu';
                   
                    break;
                case 'product5':
                    gastroFormProduct.value = 'POSbistro';
                  
                    break;
                case 'product6':
                    gastroFormProduct.value = 'NoviCloud';
                    
                    break;
                case 'product7':
                    gastroFormProduct.value = 'Leasing';
                   
                    break;
            }
        });
    });

    // SLIDER

    const slideListNode = document.querySelectorAll('.gastro-slider__item');
    const tabListNode = document.querySelectorAll('.gastro-slider__tab');

    const tabList = Array.from(tabListNode);
    const slideList = Array.from(slideListNode);

    const slide1 = document.querySelector('#slide1');
    const slide2 = document.querySelector('#slide2');
    const slide3 = document.querySelector('#slide3');
    const slide4 = document.querySelector('#slide4');
    const slide5 = document.querySelector('#slide5');

    tabList.forEach(function(elem) {
        elem.addEventListener('click', function(e) {
            /* e.preventDefault();
            e.stopPropagation(); */
            /* e.stopImmediatePropagation(); */

            slideList.forEach(function(slide) {
                slide.classList.remove('gastro-slider__item--active');
            });

            tabList.forEach(function(tab) {
                tab.classList.remove('gastro-slider__tab--active');
            });

            switch (e.target.id) {
                case 'tab1':
               
                    e.target.classList.add('gastro-slider__tab--active');
                    slide1.classList.add('gastro-slider__item--active');
                    break;
                case 'tab2':
                   
                    e.target.classList.add('gastro-slider__tab--active');
                    slide2.classList.add('gastro-slider__item--active');
                    break;
                case 'tab3':
                  
                    e.target.classList.add('gastro-slider__tab--active');
                    slide3.classList.add('gastro-slider__item--active');
                    break;
                case 'tab4':
                   
                    e.target.classList.add('gastro-slider__tab--active');
                    slide4.classList.add('gastro-slider__item--active');
                    break;
                case 'tab5':
                  
                    e.target.classList.add('gastro-slider__tab--active');
                    slide5.classList.add('gastro-slider__item--active');
                    break;
            }
        });
    });
}

(function ($) {
    // Horizontal scroll section
    var box = $(".js-gastro-hero-boxes-content"), x;
    $(".js-gastro-hero-boxes-item-button").on('click', function () {
        x = ((box.width() / 2)) + box.scrollLeft();
            box.animate({
            scrollLeft: x,
        })
    })

})(jQuery);