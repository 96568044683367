(function ($) {
    /**
     * select2
     */
    $('.js-select2-small').select2({
        minimumResultsForSearch: -1,
        theme: 'small',
        width: '100%'
    });

    $('.js-select2-tiny').select2({
        minimumResultsForSearch: -1,
        theme: 'small',
        width: '130px'
    });
})(jQuery);