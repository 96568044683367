(function ($) {
    /**
     * powiązane ze scrollem
     */

    var $slider = $('#js-partners-slider'),
        $sliderNavItem = $('.js-partners-slider-nav')

    $slider.slick({
        dots: false,
        arrows: false, 
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true
    })

    $sliderNavItem.on('click', function() {
        goToSlide($(this))
    })

    // $sliderNavItem.on('mouseover', function() {
    //     goToSlide($(this))
    // })

    function goToSlide(item) {
        var target = item.attr('data-target')
        $sliderNavItem.each(function() {
            $(this).removeClass('active')
        })
        item.addClass('active')
        $slider.slick('slickGoTo', target)
    }
    
    var $reviewsSlider = $('#js-reviews-slider')

    $reviewsSlider.slick({
        dots: true,
        arrows: true, 
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: $('#js-reviews-slider-nav'),
        prevArrow: '<span class="slider-arrow slider-arrow--prev about-reviews-slider__arrow"></span>', 
        nextArrow: '<span class="slider-arrow slider-arrow--next about-reviews-slider__arrow"></span>', 
    })

})(jQuery);