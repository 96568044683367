(function ($) {
    /**
     * powiązane ze scrollem
     */

    var slider = $('#js-reviews-section-slider');

    slider.slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        fade: true,
        cssEase: 'linear',
        slidesToScroll: 1,
        speed: 500,
        appendDots: $('#js-reviews-section-dots'),
        prevArrow:
            '<button type="button" class="home-products-slider__arrow home-products-slider__arrow--prev slick-prev"><i class="icon icon-arrow-left home-products-slider__arrow-icon"></i></button>',
        nextArrow:
            '<button type="button" class="home-products-slider__arrow home-products-slider__arrow--next slick-next"><i class="icon icon-arrow-right home-products-slider__arrow-icon"></i></button>',
    });

})(jQuery);