
var documentReady = function () {

  (function ($) {
    $('[data-toggle="tooltip"]').tooltip()

    $(document).on('mouseover', '.js-wc-variation-tooltip', function () {
        $(this).tooltip('show')
    })
    $(document).on('mouseout', '.js-wc-variation-tooltip', function () {
        $(this).tooltip('hide')
    })

})(jQuery);
};

if (
  document.readyState === "complete" ||
  (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
  documentReady();
} else {
  document.addEventListener("DOMContentLoaded", documentReady);
}
