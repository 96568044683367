const terminalHeroButton = document.querySelector('.js-terminal-hero__button');
const terminalFirstStep = document.querySelector('.terminal-steps-first-step');

/* SCROLL TO FORM */

// Not working on Sfari
/* terminalHeroButton.addEventListener('click', (e) => {
	terminalFirstStep.scrollIntoView({
		behavior: 'smooth',
		block: 'start',
		inline: 'nearest',
	});
}); 
*/

// Works on Safari
// function SmoothVerticalScrolling(e, time, where) {
//     var eTop = e.getBoundingClientRect().top;
//     var eAmt = eTop / 100;
//     var curTime = 0;
//     while (curTime <= time) {
//         window.setTimeout(SVS_B, curTime, eAmt, where);
//         curTime += time / 100;
//     }
// }

// function SVS_B(eAmt, where) {
//     if(where == "center" || where == "")
//         window.scrollBy(0, eAmt / 2);
//     if (where == "top")
//         window.scrollBy(0, eAmt);
// }

// if (document.body.classList.contains('page-landing-template')) { 
//     terminalHeroButton.addEventListener('click', function(e) {
//         SmoothVerticalScrolling(terminalFirstStep, 500, "top")
//     })
// };

/* CHOOSE TERMNAL*/

const terminalItems = document.querySelectorAll('.terminal-steps-first-step__item');
const terminalSecondStep = document.querySelector('.terminal-steps__second-step');
const terminalInputModel = document.querySelector('.terminal-form-contact__input--model');
const terminalSuccessAlert = document.getElementById('js-terminal-success-alert');
const terminalName = document.getElementById('js-terminal-name');

terminalItems.forEach(function(elem) {
	elem.addEventListener('click', function(e) {
        terminalItems.forEach(function (item) {
            item.classList.remove('terminal-steps-first-step__item--active')
        });
        
        elem.classList.toggle('terminal-steps-first-step__item--active');

        terminalSuccessAlert.classList.add('is-active')

		if (elem.classList.contains('js-first-item')) {
            terminalName.textContent = 'Terminal przenośny';
			terminalInputModel.value = 'Terminal przenośny';
		}
		if (elem.classList.contains('js-second-item')) {
            terminalName.textContent = 'Terminal stacjonarny';
			terminalInputModel.value = 'Terminal stacjonarny';
		}
		if (elem.classList.contains('js-third-item')) {
            terminalName.textContent = 'Pinpad do kasy';
			terminalInputModel.value = 'Pinpad do kasy';
		}
	});
});
