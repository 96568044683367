(function ($) {
    var $sidebar = $('#js-floating-sidebar'),
        $sidebarLimit = $('#js-floating-sidebar-end')

    $(document).ready(function() {
        /**
         * scroll to fix - inicjacja wtyczki
         */
        $sidebar.scrollToFixed({
            removeOffsets: true,
            marginTop: 150,
            limit: function(){
                return $sidebarLimit.offset().top - 160 - $(this).height();
            }
        });
        
    });

})(jQuery);