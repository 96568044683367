(function ($) {
    // Quantity "plus" and "minus" buttons
    $( document.body ).on( 'click', '.js-plus, .js-minus', function() {
        var $qty        = $( this ).closest( '.quantity' ).find( '.qty'),
            currentVal  = parseFloat( $qty.val() ),
            max         = parseFloat( $qty.attr( 'max' ) ),
            min         = parseFloat( $qty.attr( 'min' ) ),
            step        = $qty.attr( 'step' );

        // Format values
        if ( ! currentVal || currentVal === '' || currentVal === 'NaN' ) currentVal = 0;
        if ( max === '' || max === 'NaN' ) max = '';
        if ( min === '' || min === 'NaN' ) min = 0;
        if ( step === 'any' || step === '' || step === undefined || parseFloat( step ) === 'NaN' ) step = 1;

        // Change the value
        if ( $( this ).is( '.js-plus' ) ) {
            if ( max && ( currentVal >= max ) ) {
                $qty.val( max );
            } else {
                $qty.val( currentVal + parseFloat( step ) );
            }
        } else {
            if ( min && ( currentVal <= min ) ) {
                $qty.val( min );
            } else if ( currentVal > 0 ) {
                $qty.val( currentVal - parseFloat( step ) );
            }
        }

        // Trigger change event
        $qty.trigger('change');
        

        $updateButton = $('[name="update_cart"]');
        $updateButton.prop("disabled", false).trigger('click');
        $(document.body).trigger('before_cart_update')
    });

    $(document).on('change', '.woocommerce-cart .js-qty', function () {
        $('.js-odm-cart-header-qty').html('').addClass('loading');
        $('input[name="update_cart"]').removeAttr("disabled");

        $updateButton = $('[name="update_cart"]');
        $updateButton.prop("disabled", false).trigger('click');
        $(document.body).trigger('before_cart_update')
    });

    // Quantity "plus" and "minus" buttons cart product
    $( document.body ).on( 'click', '#js-change-qty-minus, #js-change-qty-plus', function() {
        var $qty = $('#js-change-qty'),
            $qtyText = $('#js-change-qty-text'),
            currentVal = parseFloat($qty.val()),
            max = parseFloat($qty.attr('max')),
            min = parseFloat($qty.attr('min')),
            step = $qty.attr('step'),
            qtyVal = 0;

        // Format values
        if ( ! currentVal || currentVal === '' || currentVal === 'NaN' ) currentVal = 0;
        if ( max === '' || max === 'NaN' ) max = '';
        if ( min === '' || min === 'NaN' ) min = 0;
        if ( step === 'any' || step === '' || step === undefined || parseFloat( step ) === 'NaN' ) step = 1;

        // Change the value
        if ( $( this ).is( '#js-change-qty-plus' ) ) {
            if ( max && ( currentVal >= max ) ) {
                $qty.val(max);
                $qtyText.text(max + ' lat');
            } else {
                qtyVal = currentVal + parseFloat(step);
                $qty.val(qtyVal);
                if (qtyVal === 1) {
                    console.log(qtyVal)
                    $qtyText.text(qtyVal + ' rok');
                } else if (qtyVal > 1 && qtyVal < 5) {
                    $qtyText.text(qtyVal + ' lata');
                } else { 
                    $qtyText.text(qtyVal + ' lat');
                }
            }
        } else {
            if (min && (currentVal <= min)) {
                $qty.val(min);
                $qtyText.text(min + ' rok');
            } else if (currentVal > 0) {
                qtyVal = currentVal - parseFloat(step);
                $qty.val(qtyVal);
                if (qtyVal === 1) {
                    $qtyText.text(qtyVal + ' rok');
                } else if (qtyVal > 1 && qtyVal < 5) {
                    $qtyText.text(qtyVal + ' lata');
                } else { 
                    $qtyText.text(qtyVal + ' lat');
                }
            }
        }
    });

})(jQuery);