(function ($) {
    /**
     * powiązane ze scrollem
     */

    var $slider = $('#js-product-slider');
    var $sliderNavItem = $('.js-product-slider-nav');

    $slider.slick({
        dots: false,
        arrows: false, 
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $sliderNavItem.on('click', function() {
        var target = $(this).attr('data-target');
        $slider.slick('slickGoTo', target);
    })

})(jQuery);