(function ($) {
    
    var show_review = '.js-wc-reviews-show',
        $content_review = $('.js-wc-reviews-content');

    $(document).on('click', show_review, function (e) {
        e.preventDefault();

        var $this = $(this);

        if ($this.hasClass('is-active')) {
            $content_review.removeClass('is-active');
            $this.removeClass('is-active').text('Zwiń');
        } else { 
            $content_review.addClass('is-active');
            $this.addClass('is-active').text('Rozwiń');
        }
    })

})(jQuery);
