(function ($) {
    /**
     * ajax add to cart
     */

    var ajaxRequest = false, ongoingAjaxRequest = false;

    $('body').on('submit', '.js-add-to-cart-form', function(e){
        e.preventDefault()

        if ($('.js-button-add-to-cart').hasClass('disabled')) {
            window.alert(wc_add_to_cart_variation_params.i18n_make_a_selection_text);
            return;
        }

        $form = $(this)
        $crossSellingModal = $('#crossSellingModal')
        data = $form.serialize();

        $('.js-add-to-cart').each(function() {
            $(this).prop('disabled', true).addClass('disabled');
        })

        if (!$form.hasClass('loading')) {
            $form.addClass('loading')
        }

        if (!$('.js-cross-selling-modal-products').hasClass('loading')) {
            $('.js-cross-selling-modal-products').addClass('loading')
        }

        ajaxRequest = ongoingAjaxRequest;

        ongoingAjaxRequest = $.ajax({
            url: ajax.url,
            data: data,
            type: 'post',
            beforeSend: function () {
                if (ajaxRequest) {
                    ajaxRequest.abort();
                }
                $('.js-odm-cart-header-qty').html('').addClass('loading');
                $form.find('.js-add-to-cart-response').remove();
            },
            success: function (response) {
                response = JSON.parse(response);
            
                if (response.status == 'SUCCESS') {
                    $form.append('<p class="js-add-to-cart-response add-to-cart-info"><span class="add-to-cart-info__inner">' + response.message + '</span></p>');
                    
                    // CrossSelling
                    $crossSellingModal.modal('show')
                    $crossSellingModal.find('.js-cross-selling-modal-product-title').text(response.product_title);
                    $crossSellingModal.find('[data-dismiss="modal"]').on("click", function() {
                        $crossSellingModal.modal('hide');
                    })
                    if (typeof response.cross_sell_ids !== 'undefined' && response.cross_sell_ids.length > 0) {
                        $crossSellingModal.find('.js-cross-selling-modal-products').removeClass('is-empty');
                        $crossSellingModal.find('#js-cross-selling-products').html(response.cross_sell_products);
                    } else { 
                        $crossSellingModal.find('.js-cross-selling-modal-products').addClass('is-empty');
                        $crossSellingModal.find('#js-cross-selling-products').empty();
                    }
                    
                    // CrossSelling koniec

                } else { 
                    $form.append('<p class="js-add-to-cart-response add-to-cart-info">' + response.message + '</p>');
                    location.reload();
                }

                $form.removeClass("loading");
                $('.js-cross-selling-modal-products').removeClass("loading");
                $(document).trigger('mda_update_cart');
                ongoingAjaxRequest = false;
            }
        });
    });

    // Modal
    $('body').on('submit', '.js-add-to-cart-form-modal', function(e){
        e.preventDefault()
        $form = $(this)
        data = $form.serialize();

        $('.js-add-to-cart-modal').each(function() {
            $(this).prop('disabled', true).addClass('disabled');
        })

        if (!$form.hasClass('loading')) {
            $form.addClass('loading')
        }

        ajaxRequest = ongoingAjaxRequest;

        ongoingAjaxRequest = $.ajax({
            url: ajax.url,
            data: data,
            type: 'post',
            beforeSend: function () {
                if (ajaxRequest) {
                    ajaxRequest.abort();
                }
                $('.js-odm-cart-header-qty').html('').addClass('loading');
                $form.find('.js-add-to-cart-response').remove();
            },
            success: function (response) {
                response = JSON.parse(response);
                
                if (response.status == 'SUCCESS') {
                    $form.append('<p class="js-add-to-cart-response add-to-cart-info add-to-cart-info--tiny"><span class="add-to-cart-info__inner">' + response.message + '</span></p>');
                } else { 
                    $form.append('<p class="js-add-to-cart-response add-to-cart-info add-to-cart-info--tiny">' + response.message + '</p>');
                    location.reload();
                }

                $form.removeClass("loading");
                $(document).trigger('mda_update_cart');
                ongoingAjaxRequest = false;
            }
        });
    });

    // Remove from cart
    $('body').on('click', '.js-remove-from-cart', function(e){
        e.preventDefault()
        var $this = $(this),
            parent_product_id = $this.data('product_id');

        ajaxRequest = ongoingAjaxRequest;
        
        ongoingAjaxRequest = _ajaxRemoveParent(parent_product_id)
    });

    $('body').on('keyup change propertychange', '.js-parent-qty', function(e){
        e.preventDefault()
        var $this = $(this);

        if ($this.val() > 0 || $this.parents('.js-parent-product-qty').length == 0 ) return;

        var parent_product_id = $this.parents('.js-parent-product-qty').data('product_id');

        ajaxRequest = ongoingAjaxRequest;
        
        ongoingAjaxRequest = _ajaxRemoveParent(parent_product_id)
    });

    // Ajax request remove parent
    function _ajaxRemoveParent(parent_product_id) {
        $.ajax({
            url: ajax.url,
            data: {
                action: 'mda_remove_from_cart',
                parent_product_id: parent_product_id
            },
            type: 'post',
            success: function (response) {
                response = JSON.parse(response);
                console.log(response)

                $(document).trigger('mda_update_cart');
                ongoingAjaxRequest = false;
            }
        });
        return false;
    }

    /**
     * Remove child from cart
     */
    
    // Click devare btn
    $('body').on('click', '.js-remove-child-from-cart', function(e){
        e.preventDefault()

        var $this = $(this),
            product_id = $this.data('product_id'),
            parent_id = $this.data('parent_id'),
            product_card = $this.data('product-card');
   
        ajaxRequest = ongoingAjaxRequest;
        
        ongoingAjaxRequest = _ajaxRemoveChild(product_id, parent_id, product_card)
    });

    // Change input qty
    $('body').on('keyup change propertychange', '.js-child-qty', function(e){
        e.preventDefault()

        var $this = $(this)

        if ($this.val() > 0 || $this.parents('.js-child-product-qty').length == 0 ) return;
        
        var product_id = $this.parents('.js-child-product-qty').data('product_id'),
            parent_id = $this.parents('.js-child-product-qty').data('parent_id'),
            product_card = $this.parents('.js-child-product-qty').data('product-card');
    
        ajaxRequest = ongoingAjaxRequest;
        
        ongoingAjaxRequest = _ajaxRemoveChild(product_id, parent_id, product_card)
    });

    // Ajax request remove child
    function _ajaxRemoveChild(product_id, parent_id, product_card) {
        $.ajax({
            url: ajax.url,
            data: {
                action: 'mda_remove_child_from_cart',
                product_id: product_id,
                parent_id: parent_id,
                product_card: product_card
            },
            type: 'post',
            success: function (response) {
                response = JSON.parse(response);
                console.log(response)

                $(document).trigger('mda_update_cart');
                ongoingAjaxRequest = false;
            }
        });
        return false;
    }

    // Add to cart child
    $('body').on('click', '.js-add-to-cart-child', function(e){
        e.preventDefault()
        var $this = $(this),
            product_id = $this.data('product_id'),
            parent_product = $this.data('parent-product'),
            variation_id = $this.data('variation-id'),
            product = $this.data('product');

        var $cart_table_tr = $this.parents('.js-cart-table-tr');
        if (!$cart_table_tr.hasClass('loading')) {
            $cart_table_tr.addClass('loading')
        }

        ajaxRequest = ongoingAjaxRequest;
        
        ongoingAjaxRequest = $.ajax({
            url: ajax.url,
            data: {
                action: 'mda_add_to_cart_child',
                product: product,
                product_id: product_id,
                parent_product: parent_product,
                variation_id: variation_id
            },
            type: 'post',
            success: function (response) {
                response = JSON.parse(response);
                console.log(response)

                if (response.status == 'SUCCESS') {
                    $this.text( response.message);
                    location.reload();
                } else { 
                    $this.text(response.message);
                    location.reload();
                }

                $cart_table_tr.removeClass("loading");
                $(document).trigger('mda_update_cart');
                ongoingAjaxRequest = false;
            }
        });
    });

    var ajaxRequestCartQty = false, ongoingAjaxRequestCartQty = false;
    $(document).on('added_to_cart updated_wc_div mda_update_cart', function () {
        ajaxRequestCartQty = ongoingAjaxRequestCartQty;

        ongoingAjaxRequestCartQty = $.ajax({
            url: ajax.url,
            data: {
                action: 'mda_get_cart_qty'
            },
            type: 'post',
            beforeSend: function () {
                if (ajaxRequestCartQty) {
                    ajaxRequestCartQty.abort();
                }
                $('.js-odm-cart-header-qty').html('').addClass('loading');
            },
            success: function (response) {
                response = JSON.parse(response);
                $('.js-add-to-cart,.js-add-to-cart-modal').each(function() {
                    $(this).prop('disabled', false).removeClass('disabled');
                })

                $('.js-odm-cart-header-qty').html(response).removeClass('loading');

                ongoingAjaxRequestCartQty = false;
            }
        });
    });

    /**
     * add loader
     */

    // trigger remove
    $('body').on('click', '.cart-table__remove', function() {
        setTimeout(function () {
            $(document.body).trigger('before_cart_update')
        }, 250)
    })

    //event
    $(document.body).on( 'before_cart_update', function() {
        if ($('.blockOverlay').length > 0) {
            var $this = $('.blockOverlay').first()

            $this.html('');
            $this.html('<svg class="loader" viewBox="-2000 -1000 4000 2000"><path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z"></path><use class="loader-use" xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px"></use></svg>')
        }
    })

    /**
     * prevent notice scroll
     */

    // $(document).ajaxCompvare(function() {
    //     if ($('body').hasClass('woocommerce-cart')) {
    //         $('html, body').stop();
    //     }
    // });

    /**
     * disable custom button add to cart
     **/
    var $formVariationAddToCart = $('.js-form-variation-add-to-cart');
    if ($formVariationAddToCart.length > 0) {

        $(document).on('change', '.variations_form', function () {

            var $buttonVariationAddToCart = $formVariationAddToCart.find('.js-button-add-to-cart-variable'),
                $buttonAddToCart = $('.js-button-add-to-cart');

            if ($buttonVariationAddToCart.hasClass('disabled')) {
                $buttonAddToCart.addClass('disabled');
            } else {
                $buttonAddToCart.removeClass('disabled');
            }

        });
    }

})(jQuery);
