(function ($) {
    /**
     * powiązane ze scrollem
     */

    var filterCheckbox = '.js-filter-checkbox';
    var $resetFilter = $('#js-reset-shop-filters');
    
    // Reset all filters
    $resetFilter.on('click', function(e){
        e.preventDefault();
        
        $(this).addClass('is-hide')
        $(filterCheckbox).filter(':checked').prop('checked', false);
    })

    // Reset all filters
    $resetFilter.on('click', function(e){
        e.preventDefault();
        
        $(this).addClass('is-hide')
        $(filterCheckbox).filter(':checked').prop('checked', false);
    })
    
    $(filterCheckbox).on('change', function () {
        $that = $(this);
        
        // #speeddevelopment - tak nie wolno robić, ale od początku było zje$%^
        $parent = $that.parent();
        $grandparent = $that.parent().parent();
        $grandgrandparent = $that.parent().parent().parent();

        var countAllChecked = $(filterCheckbox).filter(':checked').length;
        if(countAllChecked > 0){
            $resetFilter.removeClass('is-hide');
        }else{
            $resetFilter.addClass('is-hide');
        }

        if ($that.is(':checked')) {
            $parent.find(filterCheckbox).each(function () {
                $(this).prop('checked', true);
                countAllChecked = $(filterCheckbox).filter(':checked').length;

                if(countAllChecked > 0){
                    $resetFilter.removeClass('is-hide');
                }else{
                    $resetFilter.addClass('is-hide');
                }
            });
            if ($grandparent.hasClass('is-sub-menu')) {
                var checked = true;
                $grandparent.find(filterCheckbox).each(function(){
                    if(!$(this).is(':checked')){
                        checked = false;
                    }
                });
                if(checked){
                    $grandgrandparent
                        .find(filterCheckbox)
                        .first()
                        .prop('checked', true);
                }
            }

        } else {
            $parent.find(filterCheckbox).each(function () {
                $(this).prop('checked', false);
                countAllChecked = $(filterCheckbox).filter(':checked').length;

                if(countAllChecked > 0){
                    $resetFilter.removeClass('is-hide');
                }else{
                    $resetFilter.addClass('is-hide');
                }

                if ($grandparent.hasClass('is-sub-menu')) {
                    $grandgrandparent
                        .find(filterCheckbox)
                        .first()
                        .prop('checked', false);
                }
            });
        }
    });

    var $form = $('#js-shop-filter');
    var $results = $('#js-shop-query-results');
    var $select = $('#js-orderby-select');
    var $posts_per_page = $('.js-submit-product-form-on-change');

    $posts_per_page.change(function (e) {
        e.preventDefault();
        $form_data = $form.serializeArray();
        $form_data.push({ name: 'posts_per_page', value: $posts_per_page.val() });
        $results.addClass('loading');
        _ajaxLoad($form_data);
    });

    $select.change(function (e) {
        e.preventDefault();
        $form_data = $form.serializeArray();
        if ($select.val() != 'menu_order') {
            $form_data.push({ name: 'orderby', value: $select.val() });
        }
        $form_data.push({ name: 'posts_per_page', value: $posts_per_page.val() });
        $results.addClass('loading');
        _ajaxLoad($form_data);
    });

    $form.submit(function (e) {
        e.preventDefault();
        $form_data = $form.serializeArray();
        if ($select.val() != 'menu_order') {
            $form_data.push({ name: 'orderby', value: $select.val() });
        }
        $form_data.push({ name: 'posts_per_page', value: $posts_per_page.val() });
        $results.addClass('loading');
        _ajaxLoad($form_data);
    });

    function _ajaxLoad(formdata) {
        $.ajax({
            url: ajax.url,
            data: {
                action: 'load_products',
                form: formdata,
            },
            type: 'post',
            beforeSend: function () {
                $results.addClass('loading');
            },
            success: function (data) {
                data = JSON.parse(data);
                //results
                $results.html(data.view);

                //parse url
                var url = window.location.href;
                console.log(url)
                var parse_url = url.substring(0, url.indexOf('page/'));
                history.pushState(null, '', parse_url + '?' + data.url);

                //remove loading
                $results.removeClass('loading');
                var scrollOffset = $results.offset().top - 220;
                if ($(window).width() < 768) {
                    scrollOffset = scrollOffset + 40;
                }

                $('html, body').animate({ scrollTop: scrollOffset }, 'slow');

                // console.log(data)
            },
            error: function (data) {
                $results.removeClass('loading');
            },
        });
        return false;
    }
})(jQuery);
