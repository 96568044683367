jQuery(document).ready(function () {
    // Declare parallax on layers

    var target = jQuery("#js-port");
    jQuery('.js-parralax-item').parallax({
        mouseport: target
    }, {
        xparallax: -0.1,
        yparallax: -0.4,
        height: 450,
        width: -40,
        frameDuration: 20
    }, {
        xparallax: -0.07,
        yparallax: -0.2,
        height: 300,
        width: -20,
        frameDuration: 20
    });

    jQuery('.js-parralax-item-2').parallax({
        mouseport: target
    }, {
        xparallax: -0.1,
        yparallax: -0.4,
        height: 400,
        width: -120,
        frameDuration: 20
    }, {
        xparallax: -0.07,
        yparallax: -0.2,
        height: 200,
        width: -120,
        frameDuration: 20
    });

    jQuery('parallax-stop').trigger('freeze');
});