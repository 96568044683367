(function ($) {
    /**
     * checkout
     */

    $('.js-checkout-different-address').on('change', function () {
        if ($(this).prop('checked')) {
            $('.js-checkout-different-address-fields').removeClass('shipping-fields--hide');
        } else {
            $('.js-checkout-different-address-fields').addClass('shipping-fields--hide');
        }
    });

    //aktualizacja treści w pasku bocznym
    
    $(document).on('updated_checkout', function () {
        $checkoutTotalsSidebar = $('.js-checkout-totals');
        $checkoutTotalsSidebarRefreshed = $('#js-refreshed-checkout-totals');
        $sidebarContainer = $('#js-floating-sidebar-container')

        if ($checkoutTotalsSidebar.length > 0 && $checkoutTotalsSidebarRefreshed.length > 0) {
            $checkoutTotalsSidebar.html($checkoutTotalsSidebarRefreshed.html());
        }

        if ($sidebarContainer.length > 0) {
            $sidebarContainer.removeClass('loading')
        }
    });

    $(document).on('update_checkout', function () {
        $sidebarContainer = $('#js-floating-sidebar-container')
        if ($sidebarContainer.length > 0) {
            $sidebarContainer.addClass('loading')
        }
    });

    // Walidacja formularzy

    $(document).on('click', '#js-checkout-form #place_order', function (e) {
        $checkoutForm = $(document).find('#js-checkout-form');
        $addressBook = $(document).find('#js-address-book');
        if ($addressBook.length > 0) return;
        e.preventDefault();
        var data = $checkoutForm.serialize();
        data += '&action=mda_wc_validate_checkout';

        if (!$checkoutForm.hasClass('loading')) {
            $checkoutForm.addClass('loading');
        }

        $.ajax({
            url: ajax.url,
            data: data,
            type: 'post',
            beforeSend: function () {
                $('.js-muab-response').remove();
            },
            success: function (response) {
                if(response){

                    response = JSON.parse(response);

                    $checkoutForm.find('.input').each(function() {
                        $(this).removeClass('error');
                    })

                    if (response && response.status == 'SUCCESS') { 
                        $checkoutForm.submit();
                    } else {
                        $('#js-form-notice-wrapper').html('<div class="woocommerce-error">' + response.msg + '</div>');
                        $('html, body').animate({
                            scrollTop: $("#js-form-notice-wrapper").offset().top - 200
                        }, 750);
                        
                        $.each(response.errors.fields, function (key, value) {
                            $checkoutForm.find( '[name="' + key + '"]').parents('.input').addClass('error')

                            $checkoutForm.find( '[name="' + key + '"]').parents('.input').append('<p class="js-muab-response input__error-message">' + value + "</p>");
                        });
                    }
                } else {
                    $checkoutForm.submit();
                }
    
                $checkoutForm.removeClass("loading");
            }
        });
    });

})(jQuery);