(function ($)
{
    // #speeddevelopment - przepraszam, ale od początku było to dziwnie/źle zrobione
    $('.js-expand').on('click', function (e)
    {
        e.preventDefault();
        $(this).parent().parent().parent().toggleClass('is-expanded');
    });

    $('.js-filter-checkbox').on('change', function(e){

        if(!$(this).parent().parent().hasClass('is-sub-menu') && $(this).is(':checked') && $(this).parent().find('.is-sub-menu').length > 0){
            $(this).parent().addClass('is-expanded');
        }

    });


}
)(jQuery);