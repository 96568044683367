(function ($)
{
    $('#js-filterby-knowledge').on('select2:select', function (e)
    {
        var data = e.params.data;
        //get selected id value and pass to redirect to a new page with selected category of post
        var postUrl;
       
        if (data.id == 'baza-wiedzy')
        {
            postUrl = 'baza-wiedzy';
        }
        else
        {
            postUrl = 'kategoria-wpisow/'+data.id;
        }
        _redirectToNewPage(postUrl);
        
        $("#js-filterby-knowledge").select2().val(data.id).trigger('change.select2');
    });

    function _redirectToNewPage(redirectLink)
    {
        var ua = navigator.userAgent.toLowerCase(),
            isIE = ua.indexOf('msie') !== -1,
            version = parseInt(ua.substr(4, 2), 10);

        // Internet Explorer 8 and lower
        if (isIE && version < 9)
        {
            var link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
        }

        // All other browsers can use the standard window.location.href (they don't lose HTTP_REFERER like Internet Explorer 8 & lower does)
        else
        {
            var baseUrl = window.location.origin;
            window.location.href = baseUrl+'/'+redirectLink;
            // window.location.replace(`${ baseUrl }/${ redirectLink }`)
        }
    }

    $(window).on('load', function () {
        var url = window.location.href;
        $('#js-knowledge-menu a').each(function ()
        {
            if (this.href === url)
            {
                $(this).addClass('knowledge-category-filter__activeButton');
            }
        });
    } )
}
)(jQuery);