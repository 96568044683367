(function ($) {
    $('[data-toggle="tooltip"]').tooltip()

    $(document).on('mouseover', '.js-wc-variation-tooltip', function () {
        $(this).tooltip('show')
    })
    $(document).on('mouseout', '.js-wc-variation-tooltip', function () {
        $(this).tooltip('hide')
    })

})(jQuery);