(function ($) {

    /**
     * shrink header on scroll
     */

    var $mainHeader = $('#site-header')

    scrollInit();

    $(window).scroll(function() {
        scrollInit();
    });

    function scrollInit() {
        var scroll = $(window).scrollTop()

        if (scroll >= 200) { 
            $mainHeader.addClass('site-header--scroll')
        }
        else { 
            $mainHeader.removeClass('site-header--scroll')
        }
    }

})(jQuery);